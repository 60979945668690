import React, { useState, Component } from 'react';
import Masonry from 'react-masonry-component';
import GatsbyLink from 'gatsby-link';
import { graphql, navigate } from "gatsby"
import { Flex, Text, Box } from 'rebass';
import { Helmet } from 'react-helmet';
import Img from "gatsby-image"
import { StateConsumer } from '../../context/StateContext'
import Layout from '../../components/Layout'
// import MasonryFeaturedSlider from '../blocks/MasonryFeaturedSlider';
import LoaderCenterHorizontalWaypoint from '../../components/Commons/LoaderCenterHorizontalWaypoint';
// import { insertIntoArrayByIndex, createUniqueArrayNumerical } from '../../../../Helpers/ContentHelpers.js';
import { useForm, Controller } from "react-hook-form";
import FilterFeatured from '../../components/Product/FilterFeatured'
import FilterFeaturedContainer from '../../components/Product/FilterFeaturedContainer'
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  getBuilds,
  searchBuilds
} from '../../helpers/DrupalHelper';
 import LazyLoad from 'react-lazy-load';


import Select from 'react-select';

const masonryOptions = {
    transitionDuration: '0.1s',
    // stagger: 30,
    resize: true,
    columnWidth: '.grid-sizer',
    itemSelector: '.ccnMasonryItem',
    percentagePosition: true

};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

 function InspirationMasonryPage({data, pageContext, location}){

   const additionalData = pageContext.aData;

   const {
     allFormlightBuild,
     formlightInspoPage
   } = data

   const defaultItems = allFormlightBuild.nodes;

   // console.log(defaultItems)

   const defaultFilters = {
     fixture: [],
     lamp_type: [],
     mounting: []
   };

   const defaultOffsetBuilds = 2;

   const [items, setItems] = useState(defaultItems);
   const [searchState, setSearchState] = useState(false);
   const [searchStrict, setSearchStrict] = useState(false);
   const [filters, setFilters] = useState(defaultFilters);
   const [offsetBuilds, setOffsetBuilds] = useState(defaultOffsetBuilds);

   let itemInserts = pageContext.supporting;

   if(
     typeof data !== 'undefined'
     && data !== null
   ){
     itemInserts = formlightInspoPage.inspirationItems
   }

   const {
     title,
     description,
     noresult
   } = data.formlightInspoPage

   const { register, reset, control, handleSubmit, watch, errors } = useForm();

   const { fixture, lamp_type, mounting } = pageContext.aData.v;

  let lampTypes  = Object.keys(lamp_type).map(i => lamp_type[i])
  let fixtureTypes  = Object.keys(fixture).map(i => fixture[i])
  let mountingTypes  = Object.keys(mounting).map(i => mounting[i])


const clearSearch = () => {

  setSearchState(false)
  setSearchStrict(false)
  setItems(defaultItems)
  setFilters(defaultFilters)


}


const toggleStrict = () => {
  setSearchStrict((prev) => !prev);
}

const Insert = (props) => {
  let theItem = itemInserts[props.i];
  let classes = 'ccnInsertInner';
  let backgroundImage = 'none';

  if(
    typeof data !== 'undefined'
    && data !== null
  ){
    const {
      field_title,
      field_subtitle,
      field_link,
      field_button_text,
      field_image_media
    } = theItem.fields[0]

    backgroundImage = `url(${field_image_media})`;

    if(
      field_link !== null
      && field_button_text !== null) {
      classes = classes + ' withBtn'
    }

    return (
      <div style={{backgroundImage: backgroundImage, backgroundSize: `cover`, height: `100%`, backgroundPosition: `center center`}}>
      <div class={classes}>
        {field_title && <h3>{field_title}</h3>}
        {field_subtitle && <p>{field_subtitle}</p>}
        {field_button_text && field_link && <a href={field_link} className="banner-btn">{field_button_text}</a>}
      </div>
      </div>
    )


  } else if(theItem.img !== '') {
    backgroundImage = `url(${theItem.img})`;


    if(theItem.alias !== '') {
      classes = classes + ' withBtn'
    }

    return (
      <div style={{backgroundImage: backgroundImage, backgroundSize: `cover`, height: `100%`, backgroundPosition: `center center`}}>
      <div class={classes}>
        {theItem.title && <h3>{theItem.title}</h3>}
        {theItem.stitle && <p>{theItem.stitle}</p>}
        {theItem.alias && <GatsbyLink to={theItem.alias} className="banner-btn">Explore</GatsbyLink>}
      </div>
      </div>
    )

  }
  return null

}

let InsertRegular = (props) => {
  let theItem = itemInserts[props.i];
  let classes = 'ccnInsertInner';
  if(
    typeof data !== 'undefined'
    && data !== null
  ){
    const {
      field_title,
      field_subtitle,
      field_link,
      field_button_text,
      // field_image_media,
      fieldImageMedia
    } = theItem.fields[0]

    // let backgroundImage = `url(${field_image_media})`;

    let insertImage = fieldImageMedia.childImageSharp.fluid

    if(
      field_link !== null
      && field_button_text !== null) {
      classes = classes + ' withBtn'
    }

    const imageStyle = {
      objectFit: `cover`,
      position: `absolute`,
      height: `100%`,
      width: `100%`,
    }

    return (
      <div className={'ccnInsertPackery'}>
        {insertImage && <Img fluid={insertImage} alt={field_title} style={imageStyle} />}
        <div className={classes}>
          {field_title && <h3>{field_title}</h3>}
          {field_subtitle && <p>{field_subtitle}</p>}
          {field_link && field_button_text && <a href={field_link} className="banner-btn">{field_button_text}</a>}
        </div>
      </div>
    )


  } else if(theItem.img !== '') {
    let backgroundImage = `url(${theItem.img})`;

    if(theItem.alias !== '') {
      classes = classes + ' withBtn'
    }

    return (
      <div style={{backgroundImage: backgroundImage, backgroundSize: `cover`, height: `100%`, backgroundPosition: `center center`}}>
      <div className={classes}>
        {theItem.title && <h3>{theItem.title}</h3>}
        {theItem.stitle && <p>{theItem.stitle}</p>}
        {theItem.alias && <GatsbyLink to={theItem.alias} className="banner-btn">Explore</GatsbyLink>}
      </div>
      </div>
    )

  }
  return null

}

  let childElements = '';

  let nthVar = 0;
  let nthVarLimit = itemInserts.length;


  let pageMetaTitle = "Inspiration"
  let pageMetaDesc = "Inspiration"

  return (
    <>
      <Layout metaTitle={pageMetaTitle} metaDesc={pageMetaDesc}>
        <StateConsumer>
          {({updateModal, modalType, modal, addToCart, cart, token, loading, updateLoading }) => {

            const onSubmit = data => {
              setFilters(data)
              loadSearch(data)
            };

            const loadSearch = (data) => {

              let dataFixture = [];
              if(data.fixture !== null && data.fixture.length) {
                data.fixture.map((item) => {
                    dataFixture.push(item._c)
                })
              } else {
                dataFixture = [data.fixture]
              }

              let dataLampType = [];
              if(data.lamp_type !== null && data.lamp_type.length) {
                data.lamp_type.map((item) => {
                    dataLampType.push(item._c)
                })
              } else {
                dataLampType = [data.lamp_type]
              }

              let dataMountingType = [];
              if(data.mounting !== null && data.mounting.length) {
                data.mounting.map((item) => {
                    dataMountingType.push(item._c)
                })
              } else {
                dataMountingType = [data.mounting]
              }

              let method = "or"
              if(searchStrict == true){
                method = "and"
              }

              searchBuilds(token, {
                searchMethod: method,
                fixtures: dataFixture,
                lamp_type: dataLampType,
                mounting: dataMountingType
              }).then(data => {
                setSearchState(true)
                setItems(data)
              })

            }


            const loadMoreI = async () =>  {

              setOffsetBuilds(offsetBuilds + 1);

              getBuilds(token, offsetBuilds).then(data => data.map(item => {
                setItems(items => [...items, item])
              }))

            }

            const viewProduct = async (product, index) => {
              await updateLoading(true)
              await updateModal(product, 'three', index, items, additionalData)
            }

            const addProductToCart = async (productHandle) => {
              await addToCart({ handle: productHandle })
            }

            if(items) {
              childElements = items.map((product, i) => {
                let ii = i + 1;

                let lazyImage, remoteImage

                if(typeof product.imageGroup !== 'undefined'
                  && product.imageGroup !== null
                  && product.imageGroup.length > 0) {
                  lazyImage = product.imageGroup[0].childImageSharp.fluid
                }

                if(typeof product.images !== 'undefined'
                && product.images !== null
                && product.images.length > 0) {
                  remoteImage = product.images[0]
                }


                return (
                  <>
                  {(() => {
                    if(searchState == false && (ii % 32) == 0 ) {
                      nthVar = nthVar + 1;
                      if (nthVar == nthVarLimit) {
                        nthVar = 0;
                      }
                      return (
                        <div class="ccnMasonryItem ccnMasonryItem--width2 ccnMasonryItemInsert ccnMasonryItemInsertText element-item">
                          <div class="single-grid-product ">
                            <Insert i={nthVar} />
                          </div>
                        </div>);
                      }
                  })()}

                  {(() => {
                    if(searchState == false && (ii % 10) == 0 ) {
                    nthVar = nthVar + 1;
                    if (nthVar == nthVarLimit) {
                      nthVar = 0;
                    }
                    return (
                      <div class="ccnMasonryItem ccnMasonryItemInsert ccnMasonryItemInsertText element-item">
                        <div class="single-grid-product ">
                          <InsertRegular i={nthVar} />
                        </div>
                      </div>);
                    }
                  })()}

                <div class="ccnMasonryItem element-item">
               <div class="single-grid-product ccnCard">
                   <div class="product-image">
                       <a onClick={(v) => { viewProduct(product, i) }}>
                           {lazyImage !== null && <Img fluid={lazyImage} className="img-fluid imgFirst" alt="" />}
                           {!lazyImage && remoteImage !== null && <LazyLoad offsetVertical={300}><img src={remoteImage} className="img-fluid imgFirst" alt="" /></LazyLoad>}

                       </a>

                       <div class="product-action">
                           <ul>
                               <li><a title="" onClick={(v) => { viewProduct(product) }}><i class="fa fa-search-plus"></i> </a></li>
                               <li><a title="" onClick={(v) => { navigate(`/build/${product.title}`)} }><i class="fa fa-clipboard"></i> <span class="ccnL">Build info</span><span class="ccnS">Info</span></a></li>
                               <li><a onClick={(v) => { addProductToCart(product.title)} }><i class="fa fa-shopping-cart"></i> <span class="ccnL">Add to Cart</span><span class="ccnS">Buy</span></a></li>
                           </ul>
                       </div>
                   </div>
                   <div class="product-content">
                       <h3 class="title"><a onClick={(v) => { viewProduct(product) }}>{product.part_number}</a></h3>
                   </div>
               </div>
           </div>
           </>

          )})}

            return (
              <>
              <Box mx={3}>

              <div class="featHeadBox">
                <div className="contentArea">
                  <h1>{title}</h1>
                  <p>{description}</p>
                </div>
                <div className="actionArea">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FilterFeaturedContainer columns="3">
                      <FilterFeatured title="Fixture">
                        <Controller
                          name="fixture"
                          control={control}
                          as={Select}
                          options={fixtureTypes}
                          value={filters.fixture}
                          defaultValue={filters.fixture}
                          getOptionLabel={(option) => option._c}
                          getOptionValue={(option) => option.key}
                          className="basic-multi-select basic-multi-select--list"
                          classNamePrefix="select"
                          isMulti={!searchStrict}
                        />
                      </FilterFeatured>
                      <FilterFeatured title="Lamp Type">
                        <Controller
                          name="lamp_type"
                          control={control}
                          as={Select}
                          options={lampTypes}
                          value={filters.lamp_type}
                          defaultValue={filters.lamp_type}
                          getOptionLabel={(option) => option._c}
                          getOptionValue={(option) => option.key}
                          className="basic-multi-select basic-multi-select--list"
                          classNamePrefix="select"
                          isMulti={!searchStrict}
                        />
                      </FilterFeatured>
                      <FilterFeatured title="Mounting">
                        <Controller
                          name="mounting"
                          control={control}
                          as={Select}
                          options={mountingTypes}
                          value={filters.mounting}
                          defaultValue={filters.mounting}
                          getOptionLabel={(option) => option._c}
                          getOptionValue={(option) => option.key}
                          className="basic-multi-select basic-multi-select--list"
                          classNamePrefix="select"
                          isMulti={!searchStrict}
                        />
                      </FilterFeatured>
                    </FilterFeaturedContainer>
                    <div class="container position-relative">
                      {searchState === true &&
                        <input
                          onClick={() => {
                            reset()
                            clearSearch()
                          }}
                          type="button"
                          className="clearSearch"
                          value="Clear Search"
                        />
                      }
                      <div class="btn-group">
                        <div class="ccnBtnGroupItem">
                          <input class="shop-btn" type="submit" value="Search" />
                        </div>
                        <div class="ccnBtnGroupItem">
                          <label>Strict search</label>
                          <Switch checked={searchStrict} onChange={toggleStrict} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {(items == null || items.length == 0) &&
                  <div className="searchNoResult">
                  {noresult}
                  </div>
                }
              </div>


                <Masonry
                  className={'ccnInspirationGallery mb-80'} // default ''
                  elementType={'div'} // default 'div'
                  options={masonryOptions} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                  imagesLoadedOptions={imagesLoadedOptions} // default {}
                >
                  <div class="grid-sizer"></div>
                  {childElements}
                </Masonry>
                {searchState == false && <LoaderCenterHorizontalWaypoint callback={loadMoreI}/>}
                </Box>
              </>
            )
          }}
        </StateConsumer>
      </Layout>
    </>
  )
 }

// @TODO - metaValues, body description, no results text
export const query = graphql`
  query formlightInspoPage($alias: String!) {
    allFormlightBuild(limit: 1000) {
      nodes {
        part_number
        title
        alias
        images
        imageGroup {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    formlightEntities {
      v
      p
      t
    }
    formlightInspoPage(pageUrl: {eq: $alias}) {
      id
      pageUrl
      title,
      description,
      noresult
      inspirationItems {
        id
        fields {
          field_button_target
          field_button_text
          field_image_media
          field_link
          field_subtitle
          field_title
          fieldImageMedia{
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default InspirationMasonryPage;
