import React from "react";

export default function FilterFeaturedContainer(props) {
  const { columns, children } = props;

  let columnClass = 'col-md-4';

  if (columns == '3') {
    columnClass = 'col-md-4'
  } else if (columns == '4') {
    columnClass = 'col-md-3'
  }

  return (
    <div className="container mt-30">
    <div class="row filtFeatContainer">
    {children.map((item) => {


      return (
        <div className={columnClass}>
        {item}
        </div>
      )
    })}
    </div>
</div>
  );
}
