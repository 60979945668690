import React from "react";

export default function FilterFeatured(props) {
  const { title, children } = props;

  return (
    <div class="sidebar-widget mt-40 pb-45 pb-sm-20 text-left">
      <h4 class="pro-sidebar-title">
        <span>Filter by </span>
        {props.title}
      </h4>
      {props.children}
    </div>
  );
}
