import React from 'react'
import ReactDOM from 'react-dom'

import CircularProgress from '@material-ui/core/CircularProgress';
import { Waypoint } from 'react-waypoint';

function LoaderCenterHorizontal({callback}) {

  return (
    <div className="text-center mt-50 mb-50">
      <Waypoint
        onEnter={callback} />
      <CircularProgress thickness={1.3} />
    </div>
  );
}

export default LoaderCenterHorizontal;
